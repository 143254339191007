(function (d) {
  /*var s = d.getElementsByTagName('script')[0];
  var c = d.createElement('script');
  c.type = 'text/javascript';
  c.charset = 'utf-8';
  c.src = 'https://js.hsforms.net/forms/embed/v2.js';
  s.parentNode.insertBefore(c, s);*/
  
  window.hbspt = hbspt;

})(document);

